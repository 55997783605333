.each-slide > div {
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;
  height: 400px;
  border-radius: 10px;
  border: 5px solid #000000ad;
  cursor: pointer;
}

.each-slide span {
  padding: 20px;
  font-size: 20px;
  background: black;
  text-align: center;
  opacity: 0.8;
  border-radius: 10px;
}

.each-fade {
  display: flex;
}

.each-fade .image-container {
  width: 75%;
  overflow: hidden;
}

.each-fade .image-container img {
  width: 100%;
}

.each-fade h2 {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  background: #adceed;
}


@media (max-width: 900px) {
  .each-slide span {
    font-size: 1rem;
  }
  .each-slide > div {
    height: 300px;
  }
}
