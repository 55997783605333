$color-primary: #30A9DE;
$color-secondary: #C5CBE3;
$color-danger: #F03C21;
$color-waring: #D79A22;
$color-background: #EEEEEE;

$color-blue: #3956A1;
$color-yellow: #EFDC05;
$color-red: #E53A40;
$color-black: #090707;
$color-white: #EFE2BA;

@font-face {
  font-family: 'MapoPeacefull';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/MapoPeacefullA.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

body {
  background: $color-background;
}
* {
  font-family: "MapoPeacefull", Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

.ui.card>.image>img, .ui.cards>.card>.image>img {
  height: 290px;
}

.main-list-card-item {
  padding: 4px 20px 4px 4px;
}

.text-ellipsis {
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
}

.item-clickable {
  cursor: pointer;
}
.item-clickable:hover {
  z-index: 5;
  transform: translateY(-3px);
}
.button {
  cursor: pointer;
  display: inline-block;
  min-height: 1em;
  outline: 0;
  border: none;
  vertical-align: baseline;
  background: #e0e1e2 none;
  color: rgba(0,0,0,.6);
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  margin: 0 .25em 0 0;
  padding: .78571429em 1.5em .78571429em;
  text-transform: none;
  text-shadow: none;
  font-weight: 700;
  line-height: 1em;
  font-style: normal;
  text-align: center;
  text-decoration: none;
  border-radius: .28571429rem;
  box-shadow: 0 0 0 1px transparent inset, 0 0 0 0 rgba(34,36,38,.15) inset;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: opacity .1s ease,background-color .1s ease,color .1s ease,box-shadow .1s ease,background .1s ease;
  will-change: '';
  -webkit-tap-highlight-color: transparent;
}